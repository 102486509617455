@mixin semiResponsive() {
    > a {
        > img {
            max-width: 100%;
            height: auto;
        }
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
@mixin responsive() {
    width: 100%;
    > a {
        width: 100%;
        > img {
            width: 100%;
            height: auto;
        }
    }
    img {
        width: 100%;
        height: auto;
    }
}
@mixin smallSize() {
        img{
            width:80px;
            height:80px;
        }
}
@mixin mediumSize() {
        img{
            width:300px;
            height:94px;
        }
}
@mixin largeSize() {
        img {
            width: 600px;
            height: 188px;
        }
}
.vd-features-container{
    margin-right: 15px;
    margin-left: 15px;
    .image{
        overflow:auto;
        &.vd-image-size-small {
                @include smallSize();
        }
        &.vd-image-size-medium {
                @include mediumSize();
        }
        &.vd-image-size-large {
                @include largeSize();
        }
               /* Semi Responsive*/
        &.vd-image-size-semi_responsive{
                @include semiResponsive();
        }
        &.vd-image-size-responsive{
                @include responsive();
        }
    }
    &.image-style-top{
        text-align:center;
        .image{
            text-align: center;
            width:100%;
        }
        .title{
            width:100%;
        }
        .text{
            width:100%;
        }
    }
    &.image-style-left{
        display: flex;
        flex-direction: row;
        flex: 0 1 auto;
        flex-wrap: wrap;
        .text-container{
            flex: 1;
            .title{
            }
            .text{
            }
        }
        .image{
            margin-right: 15px;
            &.vd-image-size-small {
                @include smallSize();
            }
            &.vd-image-size-medium {
                @include mediumSize();
            }
            &.vd-image-size-large {
                @include largeSize();
            }
            /* Semi Responsive*/
            &.vd-image-size-semi_responsive{
                @include semiResponsive();
            }
            &.vd-image-size-responsive{
                @include responsive();
            }
        }
    }
    &.image-style-left_top{
        .text{
        }
        .image{
            > img{
                margin-right: 15px;
                width:auto;
                float:left;
            }
            .title{
            }
        }
    }
}